
<template>
    <div style="position: relative;z-index:2002">
        <CSDialog
            :dialogVisible="contentVisible"
            dialogWidth="940px"
            :dialogTitle=" title ? title : (queryType == 1 ? '加分' : '扣分')"
            @onCancel="contentVisible = false"
            dialogCancelBtnText="关闭"
            dialog-header-class="alert-bg"
            @onClose="contentVisible = false"
            :dialogShowConfirmBtn="false"
        >
            <div slot="dialog-content" style="padding: 20px 30px; font-size: 24px;" >
                <div class="row">
                    <template v-if="photos && photos.length > 0">
                        <div
                            :class="[
                                content && content != ''
                                    ? 'col-sm-7'
                                    : 'col-sm-12',
                            ]"
                        >
                            <el-carousel
                                height="400px"
                                :autoplay="false"
                            >
                                <el-carousel-item
                                    v-for="(item, n) in photos"
                                    :key="n"
                                >
                                    <img
                                        :src="item"
                                        style="
                                            min-width: 300px;
                                            max-height: 400px;
                                            display: block;
                                            margin: 0 auto;
                                        "
                                    />
                                </el-carousel-item>
                            </el-carousel>
                        </div>
                    </template>
                    <template v-if="content && content != ''">
                        <div
                            :class="[
                                photos && photos.length > 0
                                    ? 'col-sm-5'
                                    : 'col-sm-12',
                            ]"
                        >
                            <div
                                :style="{
                                    padding: '15px',
                                    'text-align':
                                        photos && photos.length > 0
                                            ? 'left'
                                            : 'center',
                                    'min-height': '120px',
                                }"
                                v-html="content"
                            ></div>
                        </div>
                    </template>
                </div>
            </div>
        </CSDialog>
    </div>
</template>
<script>
import CSDialog from "@/components/common/CSDialog";

const OWNER_TYPE = {
    OWNER: "1001",
    TENANT: "1003",
};
// 考核类型
const ASSESSMENT_TYPE = {
    BUSINESS: 2,
    COMMON: 1,
    SYSTEM: 3,
};

// 删除时的类型
const DELETE_TYPE = {
    TABLE: 1, // 删除通用考核表
    BUSINESS_ITEM: 2, // 删除业务考核项
    COMMON_ITEM: 3, // 删除通用考核项
};
// 考核人员
const ASSESSMENT_OBJECT_TYPE = {
    STAFF: 1,
    SUPERVISOR: 2,
    MANAGER: 3,
};

const AUTO_ASSESSMENT_TYPE = {
    INSPECTION: 1,
    WORK_ORDER: 2,
    ATTENDANCE: 3,
};

// 考核项目
const ASSESSMENT_ITEM_TYPE = {
    COMMON_ITEM: 1,
    BUSINESS_ITEM: 2,
};

const DEFAULT_PAGE = 1;
const DEFAULT_ROWS = 10;

export default {
    components: {
        CSDialog,
    },
    data() {
        return {
            photos: [],
            content: "",
            title: null,
            contentVisible: false,
            queryType: "",
        };
    },
    mounted() {
        this.$vc.on(this.$route.path, "viewModal", "openViewModal", this.initViewModal);
    },
    methods: {
        initViewModal(params) {
            this.title = params.title;
            this.photos =
                typeof params.photos === "string"
                    ? params.photos.split(",")
                    : params.photos;
            this.content = params.content;
            this.queryType = params.queryType;
            this.contentVisible = true;
        },
    },
};
</script>
<style lang="stylus">
</style>
