var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"position":"relative","z-index":"2002"}},[_c('CSDialog',{attrs:{"dialogVisible":_vm.contentVisible,"dialogWidth":"940px","dialogTitle":_vm.title ? _vm.title : (_vm.queryType == 1 ? '加分' : '扣分'),"dialogCancelBtnText":"关闭","dialog-header-class":"alert-bg","dialogShowConfirmBtn":false},on:{"onCancel":function($event){_vm.contentVisible = false},"onClose":function($event){_vm.contentVisible = false}}},[_c('div',{staticStyle:{"padding":"20px 30px","font-size":"24px"},attrs:{"slot":"dialog-content"},slot:"dialog-content"},[_c('div',{staticClass:"row"},[(_vm.photos && _vm.photos.length > 0)?[_c('div',{class:[
                            _vm.content && _vm.content != ''
                                ? 'col-sm-7'
                                : 'col-sm-12',
                        ]},[_c('el-carousel',{attrs:{"height":"400px","autoplay":false}},_vm._l((_vm.photos),function(item,n){return _c('el-carousel-item',{key:n},[_c('img',{staticStyle:{"min-width":"300px","max-height":"400px","display":"block","margin":"0 auto"},attrs:{"src":item}})])}),1)],1)]:_vm._e(),(_vm.content && _vm.content != '')?[_c('div',{class:[
                            _vm.photos && _vm.photos.length > 0
                                ? 'col-sm-5'
                                : 'col-sm-12',
                        ]},[_c('div',{style:({
                                padding: '15px',
                                'text-align':
                                    _vm.photos && _vm.photos.length > 0
                                        ? 'left'
                                        : 'center',
                                'min-height': '120px',
                            }),domProps:{"innerHTML":_vm._s(_vm.content)}})])]:_vm._e()],2)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }